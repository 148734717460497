import React from 'react';

import { Layout } from '../../../components/layouts';
import { Link, SEO } from '../../../components/common';
import {
  WoosaPromiseIconGridSection,
  AppointmentSection,
  StorageBaseIntroSection,
  StorageBaseBenefitSection,
  StorageBaseIntroGallerySection,
  StorageBaseAccordionSection,
  StorageBaseReviewHeroSection,
  StorageBaseUpsellGridSection,
  StorageBaseProductSection,
} from '../../../components/sections';
import { SiteUrls } from '../../../utils/constants';

const TheWoosaStorageBasePage: React.FC = () => {
  const appointmentDescription = (
    <>
      At Woosa, we’re committed to making your search for the perfect sleeping
      solutions as easy as crawling into bed. You can buy your storage bed
      online, or you’re welcome to visit our mattress showroom in Singapore.
      Experience first-hand the comfort and quality of our storage beds today,
      and explore our{' '}
      <Link to={SiteUrls.AdjustableBase2}>adjustable bed frames</Link>
      {' , pillows, and mattresses while you’re at it.'}
    </>
  );
  return (
    <Layout>
      <SEO
        title="Storage Bed Singapore | Woosa Sleep"
        titleTemplate=""
        description="Woosa’s hydraulic storage base bed is spacious and offers over 100 design combinations for your bed frame. Buy a storage bed with 30cm depth in Singapore."
        meta={[
          {
            name: 'keywords',
            content:
              'storage bed singapore, storage bed frame singapore, hydraulic storage bed singapore, hydraulic storage bed frame singapore, buy bed frame with storage, platform bed with storage singapore, buy bed with storage, buy storage bed singapore',
          },
        ]}
      />
      <StorageBaseProductSection />
      <StorageBaseIntroSection />
      <StorageBaseBenefitSection />
      <StorageBaseIntroGallerySection />
      <StorageBaseAccordionSection />
      <AppointmentSection description={appointmentDescription} />
      <StorageBaseReviewHeroSection />
      <StorageBaseUpsellGridSection />
      <WoosaPromiseIconGridSection version="v2" />
    </Layout>
  );
};

export default TheWoosaStorageBasePage;
